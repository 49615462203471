










































































































import { Franchise } from '@/models/franchise';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    franchise: {
      type: [] as PropType<Franchise[]>,
    },
  },
  methods: {
    openDialog(franchise: Franchise) {
      this.$emit('openDialog', franchise, true);
    },

    selectedFranchise(franchise: Franchise) {
      this.$emit('selectedFranchise', franchise);
    },

    openFranchiseDetailDialog(franchise: Franchise) {
      this.$emit('openFranchiseDetailDialog', franchise);
    },
  },
});
